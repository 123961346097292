<template>

  <div style="line-height: initial !important; max-height: 700px !important;">
    <div class="text-center status-card">
      <div v-if="purchaseData">
        <div style="">
          <div id="print-cards" style="word-break: break-all;">

            <!--          CashCard Print-->
            <div style="word-break: break-all;">
              <div style="padding: 0px 5px; " v-if="printReportType == 0"
              >
                <div class="table-holder flex flex-col">
                  <div class="flex flex-col">
                    <img class="table-print-logo"
                         v-if="purchaseData.inventory_list || purchaseData.transaction_pins"
                         style="max-height: 100px; margin: auto;"
                         :src="'https://e-aufladen.de' + (purchaseData.inventory_list || purchaseData.transaction_pins)[0].product_image"
                         :alt="(purchaseData.inventory_list || purchaseData.transaction_pins)[0].product_name"/>
                    <p style="font-size: 24px; font-weight: bold;" v-else>{{
                        purchaseData.product
                      }}</p>
                    <p class="table-print-amount"
                       v-if="purchaseData.inventory_list || purchaseData.transaction_pins">
                      {{
                        (purchaseData.inventory_list || purchaseData.transaction_pins)[0].product_configuration_value | germanNumberFormat
                      }}</p>

                    <p class="table-print-amount" v-else>
                      {{ purchaseData.value | germanNumberFormat }}</p>

                    <div class="py-2"></div>
                    <h2 class="select-pins-titles">{{ $t('SelectPins') }}</h2>
                    <div class="py-2"></div>
                    <vs-button class="ml-2 mt-3" color="primary" id="print-card" @click="selectAllPins()">
                      {{ $t('SelectAll') }}
                    </vs-button>
                  </div>
                  <div
                    class="flex flex-1 flex-col"
                    style="overflow-y: scroll; max-height: 350px;">

                    <div
                      v-for="inventory in (purchaseData.pins_and_batches || purchaseData.inventory_list || purchaseData.transaction_pins || pinsBatches)">
                      <vx-card class="custom-reclaim-card" v-if="inventory.is_reclaimed">
                        <vs-checkbox
                          v-if="purchaseData.pins_and_batches"
                          class="my-5"
                          v-model="pinCheck[inventory.pin]"
                          :vs-value="inventory.batch">
                          <p class="table-pin">
                            {{ inventory.pin }}
                          </p>
                        </vs-checkbox>
                        <vs-checkbox
                          v-else
                          class="my-5"
                          v-model="pinCheck[inventory.pin == undefined ? inventory.pinnumber : inventory.pin]"
                          :vs-value="purchaseData.dynamic_type == 1 ?  inventory.serial : inventory.batch">
                          <p class="table-pin">
                            {{ inventory.pin == undefined ? inventory.pinnumber : inventory.pin }}
                          </p>
                        </vs-checkbox>
                      </vx-card>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!--          CashCard Print-->


          </div>
        </div>
        <div class="py-2"></div>
        <h2 class="text-left">{{ $t('Reason') }}</h2>
        <vs-textarea :placeholder="$t('Reason')"
                     class="py-3 px-3 w-full reclaim-reason-textarea"
                     autofocus
                     v-model="remarks"/>
      </div>
    </div>

    <div class="vs-row flex justify-center">
      <vs-button class="ml-2 mt-3" color="primary" id="print-card" @click="reclaimPins()">
        {{ $t('Reclaim') }}
      </vs-button>
      <vs-button class="ml-4 mt-3" color="danger" @click="$emit('close')">{{
          $t('Close')
        }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'ReclaimPins',
  data() {
    return {
      pinCheck: {},
      remarks: '',
      pinsBatches: []
    }
  },
  props: {
    purchaseData: {
      type: Object,
    },
    printReportType: {
      type: [Number, String],
    },
  },
  mounted() {
    document.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        this.$emit('close')
      }
    })
  },
  created() {
    this.loadPinsData();
  },
  methods: {
    csvJSON(csv) {
      const lines = csv.split("\r\n");
      const result = [];
      const headers = lines[0].split(",");

      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split(",");
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentLine[j];
        }
        result.push(obj);
      }
      return result; //JSON
    },
    async loadPinsData() {
      this.pinsBatches = [];
      if (this.purchaseData.is_bulk_transaction) {
        try {
          let response = await axios.get('https://e-aufladen.de' + this.purchaseData.bulk_transaction_csv_file)
          let json = this.csvJSON(response.data.trim());
          json.forEach(item => {
            this.pinsBatches.push({
              pin: item.pin,
              is_reclaimed: true,
              batch: item.batch,
            })
          })
        } catch (e) {
          console.error(e);
        }
      } else {
        this.pinsBatches = this.purchaseData.pins_and_batches || this.purchaseData.inventory_list || this.purchaseData.transaction_pins;
      }
      if (this.pinsBatches.length == 1) {
        for (let i = 0; i < this.pinsBatches.length; i++) {
          let inventory = this.pinsBatches[i];
          if (this.purchaseData.pins_and_batches) {
            this.pinCheck[inventory.pin] = inventory.batch
          } else {
            let p = inventory.pin == undefined ? inventory.pinnumber : inventory.pin
            this.pinCheck[inventory.pin == undefined ? inventory.pinnumber : inventory.pin] = this.purchaseData.dynamic_type == 1 ? inventory.serial : inventory.batch
          }
        }
      }
    },
    selectAllPins() {
      this.pinCheck = {};
      let obj = {};
      for (let i = 0; i < this.pinsBatches.length; i++) {
        let inventory = this.pinsBatches[i];
        obj[inventory.pin] = inventory.batch
      }
      this.pinCheck = {...obj};
    },
    getFullTime(milli, tz) {
      const d = new Date(milli * 1000)
      let t = tz.replace('GMT', '')
      const op = t[0]
      t = t.replace(op, '')
      const s = t.split(':')
      let m = moment(d.toUTCString()).add(Number(s[0]), 'hours')
      m = m.add(Number(s[1]), 'minutes')
      return m.toISOString()
    },
    getAdultTypeCount(data) {
      let c = 0
      data.forEach((d) => {
        if (d.type == 'adult') {
          c += 1
        }
      })
      return c
    },
    getChildTypeCount(data) {
      let c = 0
      data.forEach((d) => {
        if (d.type == 'children') {
          c += 1
        }
      })
      return c
    },
    getBikesTypeCount(data) {
      let c = 0
      data.forEach((d) => {
        if (d.type == 'bikes') {
          c += 1
        }
      })
      return c
    },
    reclaimPins() {

      let payload = {
        transaction_id: this.purchaseData.transaction_id,
      }
      let pins = []

      Object.keys(this.pinCheck).forEach(k => {
        if (this.pinCheck[k]) {
          pins.push({
            pin: k,
            batch: this.pinCheck[k]
          })
        }
      })

      if (pins.length === 0) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select at-least one pin',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return;
      }
      payload['pins_list'] = JSON.stringify(pins)


      if (this.remarks === "") {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please fill reason',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }

      payload['reason_message'] = this.remarks

      this.$vs.loading()
      return this.$store.dispatch('reclamation/reclaimPinsNow', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
          })
          this.$emit('close')
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })

    },
  },

}
</script>

<style lang="scss">

table {
  border-collapse: collapse;
}

.td-no-padding {
  padding: 0 !important;
}

.table-no-border {
  width: 100%;

  td, th {
    border-top: none;
    border-bottom: none;
  }
}

.table-print-amount {
  font-size: 24px;
  font-weight: 600;
  padding: 10px 0px;
}

.select-pins-titles {
  font-size: 24px;
  font-weight: bold;
}

.table-pin {
  font-weight: bold;
  text-align: start;
  font-size: 18px;
  padding: 10px 8px;
}

.custom-reclaim-card .vx-card__body {
  padding: 1px 1rem !important;
}

.theme-dark {
  .reclaim-reason-textarea {
    color: white;
    border: 1px solid #ffffff !important;
  }

}
</style>
